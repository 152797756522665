import { FunctionalComponent, h, Fragment } from 'preact';
import classnames from 'classnames';

import { RatingComponentProps } from './rating';

import Star1 from './icons/stars/star-1.svg';
import Star2 from './icons/stars/star-2.svg';
import Star3 from './icons/stars/star-3.svg';
import Star4 from './icons/stars/star-4.svg';
import Star5 from './icons/stars/star-5.svg';
import Star1Selected from './icons/stars/star-1-selected.svg';
import Star2Selected from './icons/stars/star-2-selected.svg';
import Star3Selected from './icons/stars/star-3-selected.svg';
import Star4Selected from './icons/stars/star-4-selected.svg';
import Star5Selected from './icons/stars/star-5-selected.svg';

import { Scores } from '../../types/response';

import style from './rating.scss';

const ratings = {
  1: { unselected: Star1, selected: Star1Selected },
  2: { unselected: Star2, selected: Star2Selected },
  3: { unselected: Star3, selected: Star3Selected },
  4: { unselected: Star4, selected: Star4Selected },
  5: { unselected: Star5, selected: Star5Selected },
};

export const Stars: FunctionalComponent<RatingComponentProps> = ({
  selectedScore,
  onChange,
  disabledIcons,
}: RatingComponentProps) => {
  return (
    <Fragment>
      {Scores.map((score) => {
        const isSelected = score === selectedScore;
        // All the stars up to the selected score are filled
        const isFilled =
          selectedScore === undefined || selectedScore === null
            ? false
            : score <= selectedScore;
        const { unselected, selected } = ratings[score];
        const isDisabled = disabledIcons || isSelected;

        return (
          <button
            key={score}
            onClick={isDisabled ? undefined : () => onChange(score)}
            className={classnames(style.ratingButton, {
              [style.selected]: isSelected,
              [style.enabled]: !disabledIcons && !isSelected,
            })}
            disabled={isDisabled}
          >
            <img
              src={isFilled ? selected : unselected}
              alt={score}
              className={style.svgIcon}
            />
          </button>
        );
      })}
    </Fragment>
  );
};
