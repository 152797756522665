import { Fragment, FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

import { TextDirContext } from '../../contexts/textDir';
import { AsyncStatus } from './page';
import style from './unsubscribe.scss';
import { LanguageCode, unsubscribeTexts } from './texts';
import { Emoji } from '../rating/emoji';

interface PropsT {
  asyncStatus: AsyncStatus;
  onUnsubscribe: () => void;
  lang?: LanguageCode;
}

export const Unsubscribe: FunctionalComponent<PropsT> = ({
  asyncStatus,
  onUnsubscribe,
  lang,
}: PropsT) => {
  const { textDirProp } = useContext(TextDirContext) || {};

  const defaultLanguage: LanguageCode = 'en';

  const texts =
    unsubscribeTexts[(lang ?? defaultLanguage).toLowerCase() as LanguageCode] ??
    unsubscribeTexts[defaultLanguage];

  if (
    asyncStatus === AsyncStatus.Fulfilled ||
    asyncStatus === AsyncStatus.Failed
  )
    return (
      <p {...textDirProp}>
        {asyncStatus === AsyncStatus.Fulfilled ? texts.feedback : texts.error}
      </p>
    );

  return (
    <Fragment>
      <Emoji selectedScore="2" onChange={() => ''} disabledIcons></Emoji>
      <p className={style.prompt} {...textDirProp}>
        {texts.question}
      </p>
      <button
        className={style.button}
        onClick={onUnsubscribe}
        disabled={asyncStatus === AsyncStatus.Pending}
      >
        {texts.button}
      </button>
    </Fragment>
  );
};
