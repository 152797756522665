import { Fragment, Component, createRef, RefObject, h } from 'preact';
import { Rating } from '../rating';
import { CommentForm } from '../commentForm';
import { ThankYou } from '../thankYou';
import { ScoreT, EffectiveCustomization } from '../../types/response';

import style from './feedback.scss';
import { LanguageCode, unsubscribeTexts } from '../unsubscribe/texts';
import { DisabledContext } from '../../contexts/disabled';
import { DisplayContext } from '../../contexts/display';
import { TextDirContext } from '../../contexts/textDir';
import { getTextDir } from '../../utils/getTextDir';
import { Honeypot } from '../honeypot';

type PropsT = EffectiveCustomization & {
  score?: ScoreT;

  /**
   * This also determines whether the user has finished with the landing page.
   * If commentText is undefined or null, then show the CommentForm, else show
   * the ThankYou component.
   */
  commentText: string | null;
  titleMessage: string;
  ratingId?: string;
  onSubmit?: (commentText: string | null) => void;
  onError?: (e: Error) => void;
  patchScore: (score: ScoreT) => Promise<void>;
  unsubscribeUrl?: string;
  language?: LanguageCode;
};

type StateT = {
  selectedScore?: ScoreT;
  dir: 'ltr' | 'rtl' | '';
};

export class Feedback extends Component<PropsT, StateT> {
  static contextType = TextDirContext;

  private textarea: RefObject<HTMLTextAreaElement>;

  constructor(props: PropsT) {
    super(props);

    this.state = {
      selectedScore: props.score,
      dir: 'ltr',
    };

    this.textarea = createRef();
  }

  componentDidMount(): void {
    this.setTextDir();
  }

  componentDidUpdate(prevProps: PropsT): void {
    const { placeholderText, score } = this.props;

    if (placeholderText !== prevProps.placeholderText) {
      this.setTextDir();
    }

    if (score !== prevProps.score) {
      this.setState({
        selectedScore: score,
      });
    }
  }

  setSelectedScore = (score: ScoreT): void => {
    this.setState({
      selectedScore: score,
    });
  };

  // There's a bug in chrome that causes placeholder text to ignore dir="auto"
  // which is marked as 'WontFix' - https://bugs.chromium.org/p/chromium/issues/detail?id=982674
  // The actual text direction can be determined by looking at the computed value
  // (which is why there's a check for textDirProp?.dir === 'auto')
  setTextDir(): void {
    const { textDirProp } = this.context;
    const { placeholderText } = this.props;

    if (placeholderText && textDirProp?.dir === 'auto') {
      this.setState({
        dir: getTextDir(placeholderText),
      });
    }
  }

  render(): h.JSX.Element {
    const defaultLanguage: LanguageCode = 'en';
    const {
      titleMessage,
      commentQuestion,
      commentText,
      placeholderText,
      buttonText,
      scoreIconStyle,
      patchScore,
      onSubmit,
      onError,
      ratingId,
      thankYouMessage,
      unsubscribeUrl,
    } = this.props;

    const unsubscribeLinkText = (
      unsubscribeTexts[
        (this.props.language ?? defaultLanguage).toLowerCase() as LanguageCode
      ] ?? unsubscribeTexts[defaultLanguage]
    ).button;

    const { textDirProp } = this.context;

    return (
      <div id="app" className={style.root}>
        <DisplayContext.Consumer>
          {(displayContext) => (
            <Fragment>
              <DisabledContext.Consumer>
                {(disabledContext) => (
                  <Fragment>
                    <Honeypot
                      onClick={() => {
                        disabledContext.setDisabledIcons(true);
                        disabledContext.setDisabledFeedbackButton(true);
                      }}
                    />

                    {displayContext.displayTitleMessage ? (
                      <Fragment>
                        {titleMessage && (
                          <p className={style.thankYou} {...textDirProp}>
                            {titleMessage}
                          </p>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    <Rating
                      score={this.state.selectedScore}
                      onChange={this.setSelectedScore}
                      scoreIconStyle={scoreIconStyle}
                      displayTextField={displayContext.setDisplayTextField}
                      disabledFeedbackButton={
                        disabledContext.setDisabledFeedbackButton
                      }
                    />
                    {displayContext.displayTextField ? (
                      <Fragment>
                        {commentText === undefined || commentText === null ? (
                          <CommentForm
                            score={this.state.selectedScore}
                            fetchedScore={this.props.score}
                            patchScore={patchScore}
                            titleMessage={titleMessage}
                            placeholderText={placeholderText}
                            commentQuestion={commentQuestion}
                            buttonText={buttonText}
                            onSubmit={onSubmit}
                            onError={onError}
                            ratingId={ratingId}
                          />
                        ) : (
                          <ThankYou
                            commentText={commentText}
                            thankYouMessage={thankYouMessage}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    {displayContext.displayUnsubscribe ? (
                      <Fragment>
                        <a
                          className={style.unsubscribeLink}
                          href={unsubscribeUrl}
                        >
                          <p className={style.unsubscribeLink}>
                            {unsubscribeLinkText ?? 'Unsubscribe'}
                          </p>
                        </a>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                )}
              </DisabledContext.Consumer>
            </Fragment>
          )}
        </DisplayContext.Consumer>
      </div>
    );
  }
}
