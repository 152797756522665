import { FunctionalComponent, h } from 'preact';

import { DisabledContext } from '../../contexts/disabled';
import { Emojis } from './emojis';
import { Stars } from './stars';
import { Numbers } from './numbers';
import { ScoreT, EffectiveCustomization } from '../../types/response';

import style from './rating.scss';

import { log } from '../../utils/log';

export const colors = [
  // border , fill
  ['#B5C1D4', '#FFFFFF'], // 0 - grey
  ['#DB6A68', '#FFF2F2'], // 1 - red
  ['#FF9E37', '#FFEEDD'], // 2 - orange
  ['#FFD802', '#FDF8DF'], // 3 - yellow
  ['#88F004', '#E9FBE3'], // 4 - light green
  ['#33B967', '#DFFBE9'], // 5 - green
];

export type RatingComponentProps = {
  selectedScore?: ScoreT;
  onChange: (score: ScoreT) => void;
  disabledIcons: boolean;
};

type PropsT = Pick<EffectiveCustomization, 'scoreIconStyle'> & {
  score?: ScoreT;
  onChange: (score: ScoreT) => void;
  displayTextField: (disable: boolean) => void;
  disabledFeedbackButton: (disable: boolean) => void;
};

const renderScore = (
  scoreIconStyle: EffectiveCustomization['scoreIconStyle'],
  scoreProps: RatingComponentProps,
): h.JSX.Element => {
  switch (scoreIconStyle) {
    case 'numbers':
      return <Numbers {...scoreProps} />;
    case 'stars':
      return <Stars {...scoreProps} />;
    case 'emojis':
    // falls through to default Emojis
    default:
      return <Emojis {...scoreProps} />;
  }
};

export const Rating: FunctionalComponent<PropsT> = ({
  scoreIconStyle,
  score,
  onChange,
  displayTextField,
  disabledFeedbackButton,
}: PropsT) => {
  const handleChange = (score: ScoreT): void => {
    log.events.push(`click - rating ${score}`);
    displayTextField(true);
    disabledFeedbackButton(false);
    onChange(score);
  };

  return (
    <div className={style.iconContainer} data-testid="ratingContainer">
      <DisabledContext.Consumer>
        {({ disabledIcons }) =>
          renderScore(scoreIconStyle, {
            selectedScore: score,
            onChange: handleChange,
            disabledIcons,
          })
        }
      </DisabledContext.Consumer>
    </div>
  );
};
