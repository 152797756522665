import { FunctionalComponent, h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';

import { TextDirContext } from '../../contexts/textDir';
import { EffectiveCustomization } from '../../types/response';
import style from './thankYou.scss';

type PropsT = {
  commentText?: string;
  thankYouMessage: EffectiveCustomization['thankYouMessage'];
};

export const ThankYou: FunctionalComponent<PropsT> = ({
  commentText,
  thankYouMessage,
}: PropsT) => {
  const { textDirProp } = useContext(TextDirContext) || {};

  return (
    <Fragment>
      {thankYouMessage && (
        <p className={style.thankYou} {...textDirProp}>
          {thankYouMessage}
        </p>
      )}
      {commentText?.trim() && (
        <p className={style.comment} {...textDirProp}>
          {commentText}
        </p>
      )}
    </Fragment>
  );
};
